import React from "react";
import Slider from "react-slick";
import "../product_slick/slick_products.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SlickProduct = ({ slickSettings, list , customClass}) => {
    const scls = 'stadard-slick';
  return (

    <div className={`slick-product slick-container ${customClass || scls }`}>
      <Slider {...slickSettings}>
        {list.slick_items.map((item, key) => {
          let slickDescription = [];

          if (item.description.includes("\n")) {
            slickDescription = item.description.split("\n");
          }

          return (
            <div className="slick-item-container" key={key}>
              <div className="slick-upper-section">
                  <p className="slick-number">{item.number}/</p>
              </div>
                <div className="lower-section">

                    <h3 className="slick-title">{item.title}</h3>
                  {slickDescription.length > 0 ? (
                    <p>
                      {slickDescription.map((phrase, pkey) => {
                        return (
                          <div key={pkey}>
                            {phrase} <br />
                          </div>
                        );
                      })}
                    </p>
                  ) : (
                    <p>{item.description}</p>
                  )}
                </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default SlickProduct;
